<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			title="Ambientes"
			add
			edit
			remove
			@save="save($event)"
		>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="4" lg="4" md="4" sm="12">
							<s-text
								ref="textMamName"
								v-model="props.item.MamName"
								label="Nombre"
							></s-text>
						</v-col>
						<v-col cols="4" lg="4" md="4" sm="12">
							<s-text
								ref="textMamCapacity"
								number
								v-model="props.item.MamCapacity"
								label="Capacidad"
							></s-text>
						</v-col>
						<!-- 1362 -->
						<v-col cols="4" lg="4" md="4" sm="12">
							<s-select
								:items="itemsStateTunnel"
								item-value="MtsID"
								item-text="MtsDescription"
								label="Estado"
								v-model="props.item.MtsID"
							>
							</s-select>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sMaturationAmbientService from "@/services/FrozenProduction/MaturationAmbientService.js"
	import _sMaturationTunnelPalletService from '@/services/FrozenProduction/MaturationTunnelPalletService';

	export default {
		data() {
			return {
				config:{
					model: {
						MamID: "ID",
					},
					service: _sMaturationAmbientService,
					headers: [
						{text: "ID", value: "MamID"},
						{text: "Descripción", value: "MamName"},
						{text: "Capacidad", value: "MamCapacity"},
						{text: "Estado", value: "MtsDescription"},
					]
				},
				filter: {}
			}
		},

		methods: {
			initialize(){
				_sMaturationTunnelPalletService.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateTunnel = r.data
					}
				})
			},

			save(item) {
				if(item.MamName == ""){
					this.$refs.textMamName.error("Ingrese descripción");
					return;
				}
				if(item.MamCapacity == ""){
					this.$refs.textMamCapacity.error("Ingrese capacidad");
					return;
				}
				item.save();
			}
		},

		created () {
			this.initialize();
		},
	}
</script>
